import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { ModalType, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import { ToastType, useToast } from '@packages/sk8/toast'
import { AdminUser, AdminUserRole, User } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import * as yup from 'yup'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import DeleteInviteModal from 'cms/users/components/DeleteInviteModal'
import InviteUserModal from 'cms/users/components/InviteUserModal'
import UsersActions from 'cms/users/components/UsersActions'
import VerifyInvitesModal from 'cms/users/components/VerifyInvitesModal'
import type { UserInviteFormValues } from 'cms/users/types/form'
import { NetworkError } from 'common/api/types/error'
import { trpc } from 'common/hooks/trpc'
import * as usersUtils from 'common/users/utils'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import useAdminUserInviteService from './../hooks/useAdminUserInviteService'

const tagClassNamesByRole: Record<AdminUserRole, string> = {
  [AdminUserRole.Master]: 'bg-primary-100 text-primary-700',
  [AdminUserRole.MczrAdmin]: 'bg-tertiary-green-100 text-secondary-green-700',
  [AdminUserRole.EcommerceConnector]: 'bg-secondary-orange-100 text-secondary-orange-700',
  [AdminUserRole.JobRunner]: 'bg-[#D4D3EA] text-[#36317B]',
}

const validationSchema = yup.object().shape({
  emails: yup.array().of(
    yup
      .string()
      .matches(
        /^[a-zA-Z0-9_.+-]+@(gokickflip|mycustomizer)\.com$/,
        'Please enter an email with gokickflip.com or mycustomizer.com domain.'
      )
      .required('Please enter an email')
  ),
})

const AdminUsers = () => {
  const [userToDelete, setUserToDelete] = useState<User | null>(null)
  const history = useHistory()
  const match = useRouteMatch()

  const trpcUtils = trpc.useContext()
  const queryClient = useQueryClient()
  const adminUserInviteService = useAdminUserInviteService()

  const addEmailsModal = useModal()
  const verifyInviteModal = useModal()
  const deleteInviteModal = useModal()

  const { openToast, openGenericErrorToast } = useToast()
  const dropdownButton = usePopover({ placement: 'bottom-end' })

  const {
    data: admins,
    isLoading: isLoadingAdmins,
    isFetching: isFetchingAdmins,
  } = trpc.adminUser.list.useQuery(undefined, { keepPreviousData: true })

  const {
    data: invitedAdmins,
    isLoading: isLoadingInvite,
    isFetching: isFetchingInvite,
  } = useQuery(adminUserInviteService.fetchInvitedUsers.queryKeys, adminUserInviteService.fetchInvitedUsers)

  const { mutate: deleteUser, isLoading: isDeletingUser } = trpc.adminUser.delete.useMutation({
    onSuccess: () => {
      setUserToDelete(null)
      trpcUtils.adminUser.list.invalidate()
      openToast('User was successfully deleted!', ToastType.success)
    },
    onError: (error: NetworkError) => {
      setUserToDelete(null)
      if (error.status === 409) {
        openToast('Could not delete user. Master users are not deletable.', ToastType.warning)
      } else {
        openGenericErrorToast('User has not been deleted.')
      }
    },
  })

  const { mutate: deleteUserInvite, isLoading: isDeletingUserInvite } = useMutation<void, NetworkError, void>(
    () => adminUserInviteService.delete(userToDelete!.id!),
    {
      onSuccess: () => {
        setUserToDelete(null)
        queryClient.invalidateQueries(adminUserInviteService.fetchInvitedUsers.queryKeys)
        openToast('Invitation was successfully deleted!', ToastType.success)
      },
      onError: () => {
        setUserToDelete(null)
        openGenericErrorToast('Invitation has not been deleted.')
      },
    }
  )

  const { mutate: sendInvite } = useMutation<object, NetworkError, string>(
    value => adminUserInviteService.updateToken(value),
    {
      onSuccess: () => {
        openToast('Invite has been sent!', ToastType.success)
      },
      onError: () => {
        openGenericErrorToast('Invite has not been sent.')
      },
    }
  )

  const { mutate: inviteUsers, isLoading: isSending } = useMutation<User, NetworkError, UserInviteFormValues>(
    values => adminUserInviteService.invite(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(adminUserInviteService.fetchInvitedUsers.queryKeys)
        openToast('Invite has been sent!', ToastType.success)
        verifyInviteModal.close()
        formik.resetForm()
      },
      onError: error => {
        queryClient.invalidateQueries(adminUserInviteService.fetchInvitedUsers.queryKeys)
        if (error.status === 409) {
          openToast(`An email is already in use by another user`, ToastType.warning)
        } else {
          openGenericErrorToast('We’re not able to send all your invites.')
        }
      },
    }
  )

  const data = admins?.concat((invitedAdmins || []) as AdminUser[])

  const formik = useFormik<UserInviteFormValues>({
    initialValues: {
      emails: [''],
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const emailErrors: string[] | null = []
      const lowerCaseEmails = values.emails.map(email => email.toLowerCase())
      lowerCaseEmails.forEach((result, index) => {
        if (data?.some(user => user.email === result)) {
          emailErrors[index] = 'Email is already in use by another user.'
        }
      })
      setErrors({ emails: emailErrors })
      if (Object.keys(emailErrors).length > 0) {
        setSubmitting(false)
      } else {
        verifyInviteModal.open()
        addEmailsModal.close()
      }
    },
  })

  const handleClose = (modalToClose: ModalType) => {
    modalToClose.close()
  }

  const isLoading = isLoadingAdmins || isLoadingInvite
  const isFetching = isFetchingAdmins || isFetchingInvite
  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <h1>Users</h1>
          <div className="flex">
            <Button className="rounded-r-none" variant="primary" onClick={addEmailsModal.open}>
              Invite
            </Button>
            <div className="h-4 w-[1px] mt-2  bg-white mr-[-1px] z-[11] rounded" />
            <Button
              className="rounded-l-none focus:ring-0"
              variant="primary"
              aria-label="More users options"
              {...dropdownButton.referenceProps}
            >
              <Icons.ChevronDown className="w-2.5 fill-current" />
            </Button>
            <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
              <Popover.Action className="text-tertiary-black-700" onClick={() => history.push(`${match.url}/create`)}>
                Create
              </Popover.Action>
            </Popover>
          </div>
        </Page.Header>

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell className="w-3/12">
                <span>Name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-6/12">
                <span>Email</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-3/12">
                <span>Role</span>
              </Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>

          {isLoading && <Table.BodyLoader numberOfColumns={4} numberOfRows={10} />}
          {!isLoading && (
            <Table.Body>
              {isFetching && <Table.Loader colSpan={4} />}
              {admins?.map(user => {
                const fullName = usersUtils.getFullName(user)
                return (
                  <Table.Row
                    key={user.id}
                    className="cursor-pointer"
                    onClick={() => history.push(`${match.url}/${user.id}`)}
                  >
                    <Table.Cell>
                      <div className="min-w-fit">
                        {isNullOrEmpty(fullName) ? <Table.EmptyCellIndicator /> : fullName}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">{user.email}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="min-w-fit">
                        <Tag className={tagClassNamesByRole[user.role]}>{user.role}</Tag>
                      </div>
                    </Table.Cell>

                    <Table.Cell width={48}>
                      <div className="flex justify-center">
                        {user.role === 'mczr-admin' && (
                          <UsersActions
                            email={user.email}
                            isInvited={false}
                            onDelete={() => {
                              setUserToDelete(user)
                              deleteInviteModal.open()
                            }}
                          />
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
              {invitedAdmins?.map(user => (
                <Table.Row key={user.id}>
                  <Table.Cell>
                    <div className="min-w-fit">-</div>
                  </Table.Cell>
                  <Table.Cell className="flex space-x-5">
                    <div className="min-w-fit">{user.email}</div>
                    <Tag className="bg-secondary-orange-75">Pending</Tag>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="min-w-fit">
                      <Tag className="bg-tertiary-green-75">mczr-admin</Tag>
                    </div>
                  </Table.Cell>
                  <Table.Cell width={48}>
                    <div className="flex justify-center">
                      <UsersActions
                        email={user.email}
                        isInvited
                        onDelete={() => {
                          setUserToDelete(user)
                          deleteInviteModal.open()
                        }}
                        resendInvite={() => sendInvite(user.email)}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Page>
      {addEmailsModal.isVisible && (
        <InviteUserModal formik={formik} onClose={() => handleClose(addEmailsModal)} {...addEmailsModal.modalProps} />
      )}
      {verifyInviteModal.isVisible && (
        <VerifyInvitesModal
          handleModalOne={addEmailsModal}
          formik={formik}
          onClose={() => handleClose(verifyInviteModal)}
          onInvite={emails => inviteUsers({ emails })}
          isLoadingInvite={isSending}
          {...verifyInviteModal.modalProps}
        />
      )}
      {deleteInviteModal.isVisible && (
        <DeleteInviteModal
          {...deleteInviteModal.modalProps}
          userToDelete={userToDelete}
          isLoading={isDeletingUserInvite || isDeletingUser}
          onClose={deleteInviteModal.close}
          onDelete={() => {
            userToDelete?.role === AdminUserRole.MczrAdmin ? deleteUser(userToDelete.id) : deleteUserInvite()
            deleteInviteModal.close()
          }}
        />
      )}
    </main>
  )
}

export default AdminUsers
