import { IconButton } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

interface BrandAccessesActionsProps {
  onDelete: () => void
}

const BrandAccessesActions = ({ onDelete }: BrandAccessesActionsProps) => {
  const popover = usePopover({ placement: 'top-end', offsetConfig: 8 })

  return (
    <div className="relative">
      <IconButton
        variant="subtle"
        {...popover.referenceProps}
        Icon={Icons.VerticalEllipsis}
        aria-label="More options"
        small
      />
      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <Popover.Action
          className="text-tertiary-red-700"
          onClick={() => {
            popover.close()
            onDelete()
          }}
        >
          Delete
        </Popover.Action>
      </Popover>
    </div>
  )
}

export default BrandAccessesActions
