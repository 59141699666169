import { Button } from '@packages/sk8/button'
import { Icons } from '@packages/sk8/icons'
import React from 'react'

import Page from 'cms/layout/page/Page'
export interface BrandsHeaderProps {
  isMasterUser: boolean
  onCreateBrand: () => void
}

const BrandsHeader = ({ isMasterUser, onCreateBrand }: BrandsHeaderProps) => (
  <Page.Header>
    <h1>Brands</h1>
    {isMasterUser && (
      <Button
        id="create-brand-modal"
        aria-label="create brand"
        variant="primary"
        onClick={onCreateBrand}
        icon={<Icons.Add className="w-2.5 h-2.5 fill-white" />}
        className="ml-2"
      >
        Create brand
      </Button>
    )}
  </Page.Header>
)

export default BrandsHeader
