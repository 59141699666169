import { BlankState } from '@packages/sk8/blank-state'
import { IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { Icons } from '@packages/sk8/icons'
import { Tag } from '@packages/sk8/tag'
import { ToastType, useToast } from '@packages/sk8/toast'
import React from 'react'
import { useParams } from 'react-router'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import { RouterOutputs, trpc } from 'common/hooks/trpc'
import useGoBack from 'common/hooks/useGoBack'

import { jobMetadata } from '../utils'
import JobQueueForm from './JobQueueForm'

const Job = () => {
  const { openToast, openGenericErrorToast } = useToast()
  const params = useParams<{ jobName: RouterOutputs['job']['list'][number]['name'] }>()
  const goBack = useGoBack()

  const { data: job, isLoading, refetch: refetchJob } = trpc.job.get.useQuery({ jobName: params.jobName })

  const { mutate: queueTenants } = trpc.job.queueTenants.useMutation({
    onSuccess: result => {
      openToast(`${result.addedCount} brands were successfully added to the queue!`, ToastType.success)
      refetchJob()
    },
    onError: () => openGenericErrorToast('Brands were not added to the queue.'),
  })

  return (
    <main>
      <Header />
      <SideMenu />
      {job && !isLoading && (
        <>
          <Page>
            <Page.Header className="flex justify-between items-center mb-10">
              <div className="flex items-center">
                <IconButton Icon={Icons.ChevronLeft} onClick={goBack} className="mr-2" aria-label="Go back" />
                <h1>{jobMetadata[job.name].name}</h1>
              </div>
            </Page.Header>

            <Page.Section>
              <Page.Aside title="Run" description="Select a subset of brands to add to the job queue." />
              <Page.Content>
                <JobQueueForm job={job} onSubmit={queueTenants} />
              </Page.Content>
            </Page.Section>

            <Page.Separator />

            <Page.Section>
              <Page.Aside title="Running" description="The job is currently running for those brands." />
              <Page.Content>
                {job.runningTenants.length === 0 && (
                  <BlankState>
                    <BlankState.Icon Icon={Icons.DoubleCog} />
                    <BlankState.Title>Job on pause</BlankState.Title>
                    <BlankState.Details>
                      The job is not currently running, brands will be added to the queue automatically.
                    </BlankState.Details>
                  </BlankState>
                )}

                {job.runningTenants.length > 0 && (
                  <Card className="flex flex-1 flex-col">
                    <div className="p-6 flex flex-col flex-1 space-y-4">
                      <span className="text-neutral-400 text-xs leading-5 w-full">Total</span>
                      <span className="text-neutral-900 text-sm">{job.runningTenants.length}</span>
                    </div>
                    <Card.Separator />
                    <div className="p-6">
                      {job.runningTenants.map(tenant => (
                        <Tag className="rounded bg-neutral-75 mr-2 mb-2">{tenant}</Tag>
                      ))}
                    </div>
                  </Card>
                )}
              </Page.Content>
            </Page.Section>

            <Page.Separator />

            <Page.Section>
              <Page.Aside title="Queue" description="The job will run for those brands soon." />
              <Page.Content>
                {job.queuedTenants.length === 0 && (
                  <BlankState>
                    <BlankState.Icon Icon={Icons.EmptyDrawer} />
                    <BlankState.Title>Empty queue</BlankState.Title>
                    <BlankState.Details>
                      The queue is empty, you can add brands manually or wait for a brand to trigger the job
                      automatically.
                    </BlankState.Details>
                  </BlankState>
                )}

                {job.queuedTenants.length > 0 && (
                  <Card className="flex flex-1 flex-col">
                    <div className="p-6 flex flex-col flex-1 space-y-4">
                      <span className="text-neutral-400 text-xs leading-5 w-full">Total</span>
                      <span className="text-neutral-900 text-sm">{job.queuedTenants.length}</span>
                    </div>
                    <Card.Separator />
                    <div className="p-6">
                      {job.queuedTenants.map(tenant => (
                        <Tag className="rounded bg-neutral-75 mr-2 mb-2">{tenant}</Tag>
                      ))}
                    </div>
                  </Card>
                )}
              </Page.Content>
            </Page.Section>
          </Page>
        </>
      )}
    </main>
  )
}

export default Job
